.header-subpage {
	padding: 10px;
	background-color: #fff;

	&__logo {
		display: block;
		margin: 0;
		width: 240px;
		height: 69px;
		overflow: hidden;
		text-align: left;
		text-indent: -99em;
		background-size: 240px 69px;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-image: url('assets/subpage-logo.png');
	}

}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {

	.header-subpage {

		&__logo {
			background-image: url('assets/subpage-logo@2x.png');
		}

	}

}