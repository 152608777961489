#root,
#root > div,
#root > div > div {
	height: 100%;
}

.home {
	padding: 68px 0;
	min-height: 100%;
	background: #fff;

	&__inner {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -196px 0 0 -300px;
		padding: 30px;
		width: 600px;
		text-align: center;
	}

	&__logo {
		display: block;
		margin: 0 auto 20px auto;
		width: 320px;
		height: 92px;
		overflow: hidden;
		text-align: left;
		text-indent: -99em;
		background-size: 320px 92px;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-image: url('./assets/home-logo.png');
	}

	&__desc {
		margin: 0;
		font-size: 21px;
		line-height: 2;
		font-weight: bold;
	}
}

.home__footer {
	margin: -68px 0 0 0;
	padding: 20px;
	width: 100%;
	height: 68px;
	text-transform: uppercase;
	background: #1f1f1f;

	ul {
		margin: 0;
		padding: 0;
		float: right;
	}

	li {
		display: inline-block;
		padding: 0 0 0 40px;
		line-height: 2;
	}

	li a {
		text-decoration: none;
		color: #fff;
		&:hover {
			color: #999;
		}
	}

	p {
		margin: 0;
		float: left;
		line-height: 2;
		color: #fff;
	}

}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {

	.home {

		&__logo {
			background-image: url('./assets/home-logo@2x.png');
		}

	}

}

@media (max-width: 640px) {

	html {
		background: #1f1f1f;
	}

	#root,
	#root > div,
	#root > div > div {
		height: auto !important;
	}

	.home {
		padding: 0;

		&__inner {
			position: relative;
			top: 0;
			left: 0;
			margin: 0;
			padding: 30px 30px 120px 30px;
			width: auto;
		}
		
	}

	.home__footer {
		margin: 0;
		height: auto;
		overflow: hidden;
		text-align: center;
	}

}

@media (max-width: 480px) {

	.home__footer {

		ul,
		p {
			float: none;
		}

		li,
		p {
			padding: 10px;
		}

	}

}

@media (max-width: 380px) {

	.home {

		&__inner {
			padding: 30px 0 60px 0;
		}


		&__desc {
			padding: 0 30px;
		}
		
	}

}