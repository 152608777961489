* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-size: 14px;
	line-height: 1.5;
	color: #222;
	/*-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;*/
}

a {
	text-decoration: underline;
	color: #222;
}

a:hover {
	text-decoration: none;
	color: #222;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}