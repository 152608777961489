.privacy-policy {
	margin: 0 auto;
	padding: 20px 0 128px 0;
	width: 640px;
	background-color: #fff;

	&__title {
		margin: 0 0 20px 0;
		font-size: 28px;
		font-weight: normal;
		text-transform: uppercase;
		text-align: center;
	}

	&__inner {
		padding: 40px 40px 24px 40px;
		background: #f8f8f8;
	}

	&__p {
		margin: 0 0 16px 0;
		font-size: 16px;
		line-height: 2;
	}

	&__list {
		margin: 0;
		padding: 0 0 0 30px;
	}

	&__list-item {
		margin: 0 0 16px 0;
		font-weight: bold;
		font-size: 16px;
	}

	&__list-item * {
		font-weight: normal;
	}

	&__list-heading {
		margin: 0 0 16px 0;
		padding: 0;
		font-weight: bold;
		font-size: 16px;
		text-transform: uppercase;
	}

	&__list-p {
		margin: 0 0 16px 0;
		font-size: 16px;
		line-height: 2;
	}

	&__list-lvl2 {
		margin: 0 0 16px 0;
	}

	&__list-lvl2-item {
		margin: 0;
	}

	&__list-lvl2-p {
		margin: 0;
		font-size: 16px;
		line-height: 2;
	}

	&__footer {
		margin: -68px 0 0 0;
		padding: 20px;
		width: 100%;
		height: 68px;
		text-transform: uppercase;
		background: #1f1f1f;

		ul {
			margin: 0;
			padding: 0;
			float: right;
		}

		li {
			display: inline-block;
			padding: 0 0 0 40px;
			line-height: 2;
		}

		li a {
			text-decoration: none;
			color: #fff;
			&:hover {
				color: #999;
			}
		}

		p {
			margin: 0;
			float: left;
			line-height: 2;
			color: #fff;
		}

	}

}

@media (max-width: 680px) {

	.privacy-policy {
		margin: 0;
		padding: 20px 20px 88px 20px;
		width: auto;

		&__inner {
			padding: 20px 20px 4px 20px;
		}

	}

}

@media (max-width: 640px) {

	.privacy-policy {
		padding: 20px;

		&__footer {
			margin: 0;
			height: auto;
			overflow: hidden;
			text-align: center;
		}

	}

}

@media (max-width: 480px) {

	.privacy-policy__footer {

		ul,
		p {
			float: none;	
		}

		li,
		p {
			padding: 10px;
		}

	}

}